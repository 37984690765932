import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root',
})
export class RolePermissionGuard {
  constructor(private globals: Globals, private router: Router) { }

  adminRoutes = ['admin'];
  clientRoutes = ['client'];
  billTaggingRoutes = ['tag-bill'];
  tagsRoutes = ['tags'];

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.globals.authData) {

      const roles = this.globals.userRolesSlugs;
      const isAdminRoute = this.adminRoutes.includes(this.globals.currentLink.split('/')[1]);
      const isClientRoute = this.clientRoutes.includes(this.globals.currentLink.split('/')[1]);
      const isBillTaggingRoute = isAdminRoute && this.billTaggingRoutes.includes(this.globals.currentLink.split('/')[2]);
      const isBillTaggingAddEditRoute = isBillTaggingRoute && (this.globals.currentLink.split('/')[3] == 'add' || this.globals.currentLink.split('/')[3] == 'edit')
      const isTagsRoute = isAdminRoute && this.tagsRoutes.includes(this.globals.currentLink.split('/')[2]);

      if (this.globals.isClientUser && !this.globals.isAdmin && !this.globals.isStaff) {
        if (isAdminRoute) {
          this.router.navigate(['/']);
          return false;
        } else {
          return true;
        }
      } else if (this.globals.isStaff && !this.globals.isAdmin) {
        if (isTagsRoute || isBillTaggingAddEditRoute) {
          return true;
        } else {
          this.router.navigate(['/admin/tags']);
          return false;
        }
      } else if ((roles.includes('legislation-issue-manager') || roles.includes('regulatory') || roles.includes('local')) && !this.globals.isStaff && !this.globals.isAdmin) {
        if (isBillTaggingAddEditRoute) {
          return true;
        } else {
          window.location.href = this.globals.legacyStatelinkUrl;
          return false;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
}
